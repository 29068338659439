import React, { useState, useEffect, useRef } from "react";
import "./contactForm.scss";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { useLocation, Switch } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api from "../../apis/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import * as emailjs from 'emailjs-com';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ContactForm() {
  const inputProps = {
    size: "medium"
  };

  const [selectedCountry, setSelectedCountry] = useState("France");
  const [countriesDom, setCountriesDom] = useState([]);
  const [contactType, setContactType] = useState("pro");
  const [civilite, setCivilite] = useState("monsieur");
  const [cities, setCities] = useState([]);
  const inputCity = useRef(null);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [sendingMail, setSendingMail] = useState(false);

  const [email, setEmail] = useState(null);
  const [nomEntreprise, setNomEntreprise] = useState(null);
  const [nom, setNom] = useState(null);
  const [prenom, setPrenom] = useState(null);
  const [cityFr, setCityFr] = useState(null);
  const [cityNonFr, setCityNonFr] = useState(null);
  const [message, setMessage] = useState(null);

  const [openSnackBarSuccess, setOpenSnackBarSuccess] = React.useState(false);
  const [openSnackBarError, setOpenSnackBarError] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBarSuccess(false);
    setOpenSnackBarError(false);
  };

  React.useEffect(() => {
    if (!open) {
      setCities([]);
    }
  }, [open]);


  function updateCities(ev) {
    setLoading(true);
    api.searchCityByCodePostal(ev).then(res => {
      setCities(res);
      setLoading(false);
    });
  }

  function selectOption(type) {
    setContactType(type);
  }

  function countryChange(e) {
    setSelectedCountry(e);
  }

  useEffect(() => {
    const UECOUNTRIES = [
      'Austria',
      'Belgium',
      'Bulgaria',
      'Croatia',
      'Republic of Cyprus',
      'Czech Republic',
      'Denmark',
      'Estonia',
      'Finland',
      'France',
      'Germany',
      'Greece',
      'Hungary',
      'Ireland',
      'Italy',
      'Latvia',
      'Lithuania',
      'Luxembourg',
      'Malta',
      'Netherlands',
      'Poland',
      'Portugal',
      'Romania',
      'Slovakia',
      'Slovenia',
      'Spain',
      'Sweden',
      'Turkey',
    ]

    const arrDom = [];
    UECOUNTRIES.forEach(a => {
      arrDom.push(
        <MenuItem key={a} value={a}>
          {a}
        </MenuItem>
      );
    });
    setCountriesDom(arrDom);

  }, []);

  function sendMail() {
    let contactTypeToSend = null;
    let nomEntrepriseToSend = null;
    let cityToSend = null;
    let civiliteToSend = null;

    setSendingMail(true);

    if (contactType === 'pro') {
      contactTypeToSend = 'Professionnel'
      nomEntrepriseToSend = nomEntreprise? 'Nom entreprise: ' + nomEntreprise : 'Nom entreprise: ';
    } else if (contactType === 'par') {
      contactTypeToSend = 'Particulier'
    }

    if (civilite === "monsieur") {
      civiliteToSend = "Monsieur"
    } else {
      civiliteToSend = "Madame"
    }

    if (selectedCountry === "France") {
      cityToSend = cityFr;
    } else {
      cityToSend = cityNonFr;
    }

    const templateParams = {
      from_name: nom + ' ' + prenom,
      from_email: email,
      to_name: 'Alfas',
      subject: "Contact",
      reply_to: email,
      message: message,
      contactType: contactTypeToSend,
      nomEntreprise: nomEntrepriseToSend,
      civilite: civiliteToSend,
      nom: nom,
      prenom: prenom,
      selectedCountry: selectedCountry,
      city: cityToSend
    };
    emailjs.send(
      'gmail',
      'template_exgerp7',
       templateParams,
      'user_aZNdZ2TMfGdPhdd4MKoHb'
    ).then(() => {
      setSendingMail(false);
      setOpenSnackBarError(false);
      setOpenSnackBarSuccess(true);
    }, () => {
      setSendingMail(false);
      setOpenSnackBarSuccess(false);
      setOpenSnackBarError(true);
    }).catch(() => {
      setSendingMail(false);
      setOpenSnackBarSuccess(false);
      setOpenSnackBarError(true);
    })
  }

  function checkFormValidity() {
    if (!email || email === '' 
      || (!cityFr && !cityNonFr) || (cityFr === '' && cityNonFr === '') 
      || !selectedCountry || selectedCountry === ''
      || !message || message === '') {
      return false;
    }
    if (email && (!email.includes('@') || !email.includes('.'))) {
      return false;
    }
    if (contactType === 'pro') {
      if (!nomEntreprise || nomEntreprise === '') {
        return false;
      }
    }
    if (!nom || nom === ''
      || !prenom || prenom === '') {
      return false;
    }
    return true;
  }

  return (
    <div id="contactForm" className="contactForm">
      <div className="content">
        <div className="title">
          <span>Nous contacter</span> <span className="title-msg">(tous les champs sont obligatoires)</span></div>

        <div className="flex-div-col">
            <TextField
              required
              error={email && (!email.includes('@') || !email.includes('.'))}
              type="email"
              id="email"
              label="Votre adresse mail"
              helperText={email && (!email.includes('@') || !email.includes('.')) ? 
              "Adresse mail incorrect." : null}
              onChange={($event) => setEmail($event.target.value)}
            />
          </div>

        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
          className="buttonGroup contactTypeDiv"
        >
          <Button
            className={contactType === "pro" ? "active-contactType" : ""}
            onClick={e => selectOption("pro")}
          >
            Professionel
          </Button>
          <Button
            className={contactType === "par" ? "active-contactType" : ""}
            onClick={e => selectOption("par")}
          >
            Particulier
          </Button>
        </ButtonGroup>

        {contactType === "pro" && (

          <div className="flex-div-col">
            <TextField
              required
              id="nameEntreprise"
              label="Nom entreprise"
              onChange={($event) => setNomEntreprise($event.target.value)}
            />
          </div>
        )}

        <div className="flex-div-col">
          <TextField
            required
            id="name"
            label="Nom"
            inputProps={inputProps}
            onChange={($event) => setNom($event.target.value)}
          />

          <TextField
            required
            id="firstname"
            label="Prénom"
            inputProps={inputProps}
            onChange={($event) => setPrenom($event.target.value)}
          />

          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
            className="buttonGroup"
          >
            <Button
              className={civilite === "monsieur" ? "active-civilite" : ""}
              onClick={e => setCivilite("monsieur")}
            >
              M.
            </Button>
            <Button
              className={civilite === "madame" ? "active-civilite" : ""}
              onClick={e => setCivilite("madame")}
            >
              Mme
            </Button>
          </ButtonGroup>
        </div>

        <InputLabel id="country-label">Pays *</InputLabel>
        <Select
          labelId="country-label"
          value={selectedCountry}
          onChange={e => countryChange(e.target.value)}
          className="country-select"
        >
          {countriesDom}
        </Select>

        {selectedCountry === "API MAX REQUEST" && (
          <Autocomplete
            id="async-cities"
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={(event, value) => setCityFr(value? (value.code + ' ' + value.city) : '')}
            getOptionSelected={(option, value) =>
              option.code.toString() === value.code.toString()
            }
            getOptionLabel={option =>
              option.code.toString() + " " + option.city.toString()
            }
            options={cities}
            renderOption={option => (
              <React.Fragment>
                {option.code}&nbsp;{option.city}
              </React.Fragment>
            )}
            noOptionsText="Pas d'options disponibles"
            renderInput={params => (
              <TextField
                ref={inputCity}
                {...params}
                label="Ville *"
                variant="outlined"
                onChange={e => updateCities(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
        )}

        {selectedCountry !== "" && (
          <TextField
            required
            id="cityNoneFr"
            label="Ville"
            inputProps={inputProps}
            onChange={($event) => setCityNonFr($event.target.value)}
          />
        )}

        <TextareaAutosize
          rowsMax={5}
          placeholder="Votre message *"
          className="message_textarea"
          maxLength="500"
          onChange={($event) => setMessage($event.target.value)}
        />

        <div className="send-div mrg-top-20">
          <button className="btn btn-border-4 send-contact-btn"
            onClick={sendMail} disabled={!checkFormValidity()}>
            Envoyer
            {
              sendingMail &&
              <CircularProgress size={25} />
            }
          </button>
        </div>

      </div>

      <Snackbar open={openSnackBarSuccess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Votre mail a été envoyé. Nous allons vous re-contacter le plus tôt possible. Alfas.
        </Alert>
      </Snackbar>

      <Snackbar open={openSnackBarError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Votre mail n'a pas pû être envoyé. Veuillez reéssayer.
        </Alert>
      </Snackbar>


    </div>
  );
}

export default ContactForm;
