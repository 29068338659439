import React, { useState, useEffect } from "react";
import "./about.scss";
import { Link } from "react-router-dom";
import BurgerMenu from "../burgerMenu/burgerMenu";
import NormalMenu from "../normalMenu/normalMenu";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as emailjs from 'emailjs-com';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import api from "../../apis/api";

function About() {
  const [displayHeaderMobile, setDisplayHeaderMobile] = useState(false);

  const [email, setEmail] = useState(null);
  const [nom, setNom] = useState(null);
  const [prenom, setPrenom] = useState(null);
  const [tel, setTel] = useState(null);
  const [activitePro, setActivitePro] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [cityToImplant, setCityToImplant] = useState(null);
  const [gamme, setGamme] = useState(null);
  const [message, setMessage] = useState(null);


  const [availableIsonemGammes, setAvailableIsonemGammes] = useState([]);

  const [sendingMail, setSendingMail] = useState(false);
  const [openSnackBarError, setOpenSnackBarError] = React.useState(false);
  const [openSnackBarSuccess, setOpenSnackBarSuccess] = React.useState(false);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBarSuccess(false);
    setOpenSnackBarError(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    api.getGammes().then((res) => {
      const gammes = res.filter((g) => g.marque.id === "5f6a5722a49b7") // isonem id
      if (gammes) {
        console.log('gammes filtered', gammes)
        setAvailableIsonemGammes(gammes);
      }
    })

  }, []);

  function sendMail() {
    setSendingMail(true);

    const templateParams = {
      from_name: nom + ' ' + prenom,
      from_email: email,
      to_name: 'Alfas',
      subject: "Devenir Revendeur",
      reply_to: email,
      message: message,
      nom: nom,
      prenom: prenom,
      tel: tel,
      companyName: companyName,
      activitePro: activitePro,
      cityToImplant: cityToImplant,
      gamme: gamme,
    };
    emailjs.send(
      'gmail',
      'template_0tvehyj',
       templateParams,
      'user_aZNdZ2TMfGdPhdd4MKoHb'
    ).then(() => {
      setSendingMail(false);
      setOpenSnackBarError(false);
      setOpenSnackBarSuccess(true);
    }, () => {
      setSendingMail(false);
      setOpenSnackBarSuccess(false);
      setOpenSnackBarError(true);
    }).catch(() => {
      setSendingMail(false);
      setOpenSnackBarSuccess(false);
      setOpenSnackBarError(true);
    })
  }

  function checkFormValidity() {
    if (!email || email === '' 
    || (!tel) || (tel === '') 
    || !gamme || gamme === ''
    || !message || message === ''
    || !activitePro || activitePro === ''
    || !companyName || companyName === ''
    || !cityToImplant || cityToImplant === '') {
      return false;
    }
    if (email && (!email.includes('@') || !email.includes('.'))) {
      return false;
    }
    if (!nom || nom === ''
      || !prenom || prenom === '') {
      return false;
    }
    return true;
  }

  return (
    <div className="about">
      <div id="about-header" className="about-header">
        <div className="top-header">
          <div className="top-part">
            <Link to="/">
              <img
                id="logo-header-about"
                className="logo animate__animated animate__bounceInLeft"
                src="/assets/img/alfas-isonem-france.png"
              />
            </Link>
            <NormalMenu initialState="aproposs"/>
            <BurgerMenu isOpen={true} />
          </div>
        </div>
      </div>

      <div className="about-body">
        <div className="section">
          <div className="left">
            <div className="custom-title-div-2">
              <div className="title">Qui sommes nous ?</div>
              <div className="sub-title">ALFAS</div>
            </div>

            <div className="description-div">
              <div>
                <p>ALFAS vous propose des solutions performantes de qualité durable afin d’améliorer le confort de
                chacun au quotidien.
              <br/><br/>En alliant innovation et performance, nos produits sont conçus pour répondre à vos besoins tout
              en préservant notre planète bleue.</p>
              </div>
              <div className="floatRightDiv" data-aos="zoom-out">Construisons le monde de demain.</div>
              <div className="contact-info">
                <div className="contact" data-aos="fade-right">
                  <i className="fas fa-map-marked"></i>
                  <div className="text">
                    <a href="https://goo.gl/maps/rNanSw4C6q1XTj8WA" target="_blank">
                      117 Rue Du Puech, 30310 Vergèze
                    </a>
                  </div>
                </div>

                <div
                  className="contact"
                  data-aos="fade-right"
                  data-aos-delay="300"
                >
                  <i className="fas fa-phone"></i>
                  <div className="text">
                   <a href="tel:+33448710030">+33 4 48 71 00 30</a>
                  </div>
                </div>

                <div
                  className="contact"
                  data-aos="fade-right"
                  data-aos-delay="600"
                >
                  <i className="fas fa-envelope"></i>
                  <div className="text">
                    <a href="mailto:contact@alfas.fr">contact@alfas.fr</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="right">
            <img
              src={process.env.PUBLIC_URL + "./assets/img/about/about1.JPG"}
            />
          </div>
        </div>

        <div className="section section-alfas-isonem" data-aos="fade-right" data-aos-delay="200">
          <div className="left">
            <div className="custom-title-div-2">
              <div className="sub-title">ALFAS <span className="specialE">&nbsp;&&nbsp;</span> ISONEM</div>
            </div>

            <div className="description-div">
              <div>
                <p>Depuis sa création en 2004, la recherche constante d’innovation et de développement permet
                aujourd’hui à la firme d’être présente dans près de 100 pays.
                La réussite de la marque ISONEM sur le marché mondial n’est pas un simple hasard, elle a su
                démontrer son sérieux par la qualité de ses produits.
                Avec son centre d’exploitation d’une surface de 10 000m2 et de son personnel technique de
                qualité, ISONEM a pour mission de présenter une terre plus habitable tout en protégeant
                l’humanité et l’environnement.
                <br/><br/>ALFAS est le distributeur de ISONEM en France.
                </p>
              </div>
            </div>
          </div>

          <div className="right">
            <img
              src={process.env.PUBLIC_URL + "./assets/img/about/usine.png"}
            />
          </div>
        </div>

        <div className="section section-video-alfas" data-aos="fade-right" data-aos-delay="200">
          <div>
            <div className="custom-title-div-2">
              <div className="sub-title">NOUS&nbsp;DECOUVRIR</div>
            </div>

            <div className="video-div">
              <video
                controls
                controlslist="nodownload"
                id="videopresentationalfas"
                className="video"
                src={process.env.PUBLIC_URL + "./assets/videopresentation.mp4"}
                type="video/mp4"
                poster={process.env.PUBLIC_URL + "./assets/img/about/preview.png"}
              ></video>
            </div>
          </div>
        </div>


        <div className="section section-devenez-revendeurs" data-aos="fade-right" data-aos-delay="200"
        id="devenir-revendeur">
          <div>
            <div className="custom-title-div-2">
              <div className="sub-title">DEVENEZ REVENDEUR ISONEM</div>
            </div>
          </div>

          <div className="title-devenez-revendeur">
            Pourquoi devenir revendeur des produits ISONEM ?
          </div>

          <div className="div-devenez-revendeurs">
            <img src={process.env.PUBLIC_URL + "./assets/img/about/revendeurs.png"} />
            <div className="points">
              <ul>
                <li>
                Proposer des solutions performantes de qualité durable afin d’améliorer le
confort de chacun tout en préservant notre planète bleue.
                </li>

                <li>
                Assister au développement d’une marque en plein essor
                </li>

                <li>
                Être accompagné durant toutes les étapes de votre projet
                </li>

                <li>
                Participer à une aventure humaine enrichissante
                </li>

                <li>
                Contribuer à la mission ISONEM : présenter une terre plus habitable tout en
protégeant l’humanité et l’environnement.
                </li>
              </ul>
            </div>
          </div>

          <div className="form-div-revendeur">

            <div className="title-become-revendeur">
              Prêt à devenir revendeur des produits ISONEM ?
            </div>

            <div className="title-fill-form-revendeur">
                Remplissez notre formulaire
            </div>

            <div className="form-revendeurs">
              <TextField
                required
                id="prenom"
                label="Prénom"
                onChange={($event) => setPrenom($event.target.value)}
              />
              <TextField
                required
                id="nom"
                label="Nom"
                onChange={($event) => setNom($event.target.value)}
              />
              <TextField
                required
                error={email && (!email.includes('@') || !email.includes('.'))}
                type="email"
                id="email"
                label="Email"
                helperText={email && (!email.includes('@') || !email.includes('.')) ? 
                "Adresse mail incorrect." : null}
                onChange={($event) => setEmail($event.target.value)}
              />
              <TextField
                required
                id="nom"
                label="Téléphone"
                onChange={($event) => setTel($event.target.value)}
              />
              <TextField
                required
                id="activitePro"
                label="Activité professionnelle actuel"
                onChange={($event) => setActivitePro($event.target.value)}
              />
              <TextField
                required
                id="companyName"
                label="Nom de votre enseigne "
                onChange={($event) => setCompanyName($event.target.value)}
              />
              <InputLabel id="gamme-label">Gamme de produit souhaitée</InputLabel>
              <Select
                labelId="gamme-label"
                value={gamme}
                onChange={e => setGamme(e.target.value)}
                className="gamme-select"
              >
                {
                  availableIsonemGammes.map((g) => {
                    return (
                      <MenuItem value={g.nom}>{g.nom}</MenuItem>
                    );
                  })
                }
                
              </Select>
              <TextField
                required
                id="cityToImplant"
                label="Ville souhaitée"
                onChange={($event) => setCityToImplant($event.target.value)}
              />

              <TextareaAutosize
                rowsMax={5}
                placeholder="Votre message *"
                className="message_textarea"
                maxLength="500"
                onChange={($event) => setMessage($event.target.value)}
              />

              <div className="send-div mrg-top-20">
                <button className="btn btn-border-4 send-contact-btn"
                  onClick={sendMail} disabled={!checkFormValidity()}>
                  Envoyer
                  {
                    sendingMail &&
                    <CircularProgress size={25} />
                  }
                </button>
              </div>
            </div>
          </div>

          <Snackbar open={openSnackBarSuccess} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Votre mail a été envoyé. Nous allons vous re-contacter le plus tôt possible. Alfas.
            </Alert>
          </Snackbar>

          <Snackbar open={openSnackBarError} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              Votre mail n'a pas pû être envoyé. Veuillez reéssayer.
            </Alert>
          </Snackbar>


        </div>
      </div>
    </div>
  );
}

export default About;
