import axios from "axios";

function csvToJSON(csv) {

    //lop off any trailing or starting whitespace
    csv = csv.trim();

    //prep
    let lines = csv.split('\n'),
        headers,
        output = [];

    //iterate over lines...
    lines.forEach((line, i) => {

        //...break line into tab-separated parts
        let parts = line.split(/;/);

        //...if not the headers line, push to output. By this time
       //we have the headers logged, so just match the value to
       //header of the same index
        if (i) {
            let obj = {};
            parts.forEach((part, i) => obj[headers[i]] = part);
            output.push(obj);

        //...else if headers line, log headers
        } else
            headers = parts;
    })

    //done
    return output;
}

function isDigit(c) {
    var re = /^\d$/;
    return re.test(c);
}

const api = {

    getCities() {
        const apiUrl = "https://www.data.gouv.fr/fr/datasets/r/3b2e2918-6c27-4edb-8155-7d03e0489d39"
        return new Promise((resolve, reject) => {
            axios.get(apiUrl).then((res) => {
                const json = csvToJSON(res.data);
                resolve(json);
            });
        });
    },

    searchCityByCodePostal(codePostal) {
        if (codePostal) {
            if (isDigit(codePostal[0])) {
                if (codePostal.length === 1) {
                    codePostal = codePostal + '0000'
                }
                if (codePostal.length === 2) {
                    codePostal = codePostal + '000'
                }
            }
            const apiUrl = "https://vicopo.selfbuild.fr/cherche/" + codePostal
            return new Promise((resolve, reject) => {
                axios.get(apiUrl).then((res) => {
                    const json = res.data.cities;
                    resolve(json);
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve([]);
            });
        }
    },

    getCountries() {
        const apiUrl = 'https://country.register.gov.uk/records.json?page-size=5000'
            return new Promise((resolve, reject) => {
                axios.get(apiUrl).then((res) => {
                    resolve(res.data);
                });
        });
    },

    getProducts() {
        const apiUrl = 'https://alfas.fr/api/produit'
            return new Promise((resolve, reject) => {
                axios.get(apiUrl).then((res) => {
                    resolve(res.data);
                });
        });
    },

    getProduct(id) {
        const apiUrl = 'https://alfas.fr/api/produit/' + id
            return new Promise((resolve, reject) => {
                axios.get(apiUrl).then((res) => {
                    resolve(res.data);
                });
        });
    },

    getMarque() {
        const apiUrl = 'https://alfas.fr/api/marque'
            return new Promise((resolve, reject) => {
                axios.get(apiUrl).then((res) => {
                    resolve(res.data);
                });
        });
    },

    
    getRevendeurs() {
        const apiUrl = 'https://alfas.fr/api/revendeur'
            return new Promise((resolve, reject) => {
                axios.get(apiUrl).then((res) => {
                    resolve(res.data);
                });
        });
    },

    getGammes() {
        const apiUrl = 'https://alfas.fr/api/gamme'
            return new Promise((resolve, reject) => {
                axios.get(apiUrl).then((res) => {
                    resolve(res.data);
                });
        });
    },

    getGamme(gammeId) {
        const apiUrl = 'https://alfas.fr/api/gamme/' + gammeId
            return new Promise((resolve, reject) => {
                axios.get(apiUrl).then((res) => {
                    resolve(res.data);
                });
        });
    },

    getProduitDuMois() {
        const apiUrl = 'https://alfas.fr/api/produitDuMois/'
        return new Promise((resolve, reject) => {
            axios.get(apiUrl).then((res) => {
                resolve(res.data);
            });
        });
    }


}

export default api;