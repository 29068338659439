import React, { useState, useEffect, useRef } from "react";
import "./home.scss";
import { Link } from "react-router-dom";
import BurgerMenu from "../burgerMenu/burgerMenu";
import ContactForm from "../contactForm/contactForm";
import { Map, Marker, Popup, TileLayer, Tooltip } from "react-leaflet";
import TextField from "@material-ui/core/TextField";
import IconButton from '@material-ui/core/IconButton';
import api from "../../apis/api";
import NormalMenu from "../normalMenu/normalMenu";

function Home() {
  const [isMobile, setIsMobile] = useState(false);
  const [mapCenter, setMapCenter] = useState([43.744749, 4.205976]); // alfas coordinate
  const [mapZoom, setZoom] = useState(9);
  const [revendeursDom, setRevendeursDom] = useState([]);
  const [marquesDom, setMarquesDom] = useState([]);
  const [revendeurs, setRevendeurs] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [muted, setMuted] = useState(true);

  const [productOfMonth, setProductOfMonth] = useState({});

  const textInputRevendeur = useRef(null);

  function zoomOn(lat, long) {
    setMapCenter([lat, long]);
    setZoom(13);
  }

  const map = (
    <Map center={mapCenter} zoom={mapZoom}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {markers}
    </Map>
  );

  useEffect(() => {
    setIsMobile(detectMob());

    api.getMarque().then(res => {
      const marques = [];
      res.forEach((r, indx) => {
        const gammes = [];
        r.gammes.forEach(g => {
          if (gammes.length <= 2) {
            gammes.push(<p>{g.nom}</p>);
          }
        });

        const marque = (
          <Link to={"/produits/" + r.nom}>
            <div className="product-category grow">
              <img className="img" src={r.logo} />
              <div className="label">
                <div>
                  {gammes}
                  <p style={{ fontWeight: 500 }}>.&nbsp;&nbsp;.&nbsp;&nbsp;.</p>
                </div>
              </div>
            </div>
          </Link>
        );

        marques.push(marque);
      });
      setMarquesDom(marques);
    });

    api.getRevendeurs().then(res => {
      const revendeursDomLocal = [];
      const markersLocal = [];

      setRevendeurs(res);

      res.forEach((r, indx) => {
        if (indx !== 0) {
          revendeursDomLocal.push(
            <i className="fas fa-chevron-left slide-left-status"></i>
          );
        }
        
        revendeursDomLocal.push(
          <div
            key={indx}
            className="revendeur"
            onClick={$event => {
              if (r.latitude && r.longitude) {
                zoomOn(+r.latitude, +r.longitude);
              }
            }}
          >
            <div className="left">
              <img className="revendeur_img" src={r.logo} />
            </div>
            <div className="right">
              <strong>{r.nom}</strong>
              <div>{r.codePostal}&nbsp;&nbsp;{r.ville}</div>
              <div>{r.adresse}</div>
              <div>{r.numeroTelephone}</div>
            </div>
          </div>
        );

        if (indx !== res.length - 1 && res.length > 1) {
          revendeursDomLocal.push(
            <i className="fas fa-chevron-right slide-right-status"></i>
          );
        }

        markersLocal.push(
          <Marker key={indx} position={[r.latitude, r.longitude]}>
            <Tooltip>
              <div className="marker_content">
                <img className="marker_img" src={r.logo} />
                <strong>{r.nom}</strong>
                <div>{r.codePostal}&nbsp;&nbsp;{r.ville}</div>
                <div>{r.adresse}</div>
                <div>{r.numeroTelephone}</div>
              </div>
            </Tooltip>
          </Marker>
        );
      });
      setMarkers(markersLocal);
      setRevendeursDom(revendeursDomLocal);
    });

    api.getProduitDuMois().then(res => {
      let desc = res.description.substring(0, 500);
      desc[desc.length - 1].replace(/\//gi, "");
      desc[desc.length - 1].replace(/</gi, "");
      desc[desc.length - 1].replace(/>/gi, "");
      desc = desc + "&nbsp;&nbsp;. . . ";
      res.description = desc;
      setProductOfMonth(res);
    });
  }, []);

  function detectMob() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      console.log('navigator.userAgent', navigator.userAgent)
        return navigator.userAgent.match(toMatchItem);
    });
}

  function filterRevendeurList(e) {
    const revendeursDomLocal = [];
    let revendeursLocal = [...revendeurs];
    revendeursLocal = revendeursLocal.filter(
      r =>
        (r.nom && r.nom.toLowerCase().includes(e.toLowerCase())) ||
        (r.ville && r.ville.toLowerCase().includes(e.toLowerCase())) ||
        (r.adresse && r.adresse.toLowerCase().includes(e.toLowerCase())) ||
        (r.codePostal && r.codePostal.toLowerCase().includes(e.toLowerCase()))
    );

    revendeursLocal.forEach((r, indx) => {
      if (indx !== 0) {
        revendeursDomLocal.push(
          <i className="fas fa-chevron-left slide-left-status"></i>
        );
      }

      revendeursDomLocal.push(
        <div
          className="revendeur"
          onClick={$event => r.latitude && r.longitude ?
            zoomOn(+r.latitude, +r.longitude) : null}
        >
          <div className="left">
            <img className="revendeur_img" src={r.logo} />
          </div>
          <div className="right">
            <strong>{r.nom}</strong>
            <div>{r.codePostal}&nbsp;&nbsp;{r.ville}</div>
            <div>{r.adresse}</div>
            <div>{r.numeroTelephone}</div>
          </div>
        </div>
      );

      if (indx !== revendeursLocal.length - 1 && revendeursLocal.length > 1) {
        revendeursDomLocal.push(
          <i className="fas fa-chevron-right slide-right-status"></i>
        );
      }
    });
    setRevendeursDom(revendeursDomLocal);
  }

  function handleClear() {
    textInputRevendeur.current.value = '';
    filterRevendeurList('');
  }

  return (
    <div className="homepage">
      <div id="homepage-header" className="homepage-header">
        <div className="top-header">
          <div className="top-part">
            <Link to="/">
              <img
                id="logo-header-homepage"
                className="logo animate__animated animate__bounceInLeft"
                src="/assets/img/alfas-isonem-france.png"
              />
            </Link>
            <NormalMenu/>
            <BurgerMenu isOpen={true} />
          </div>


          <section className="section-video">
            <video
              id="videopresentationalfas"
              autoplay="autoplay"
              loop
              className="video"
              muted={muted}
              webkit-playsinline="true"
              playsinline="true"
              src={process.env.PUBLIC_URL + "./assets/alfas-isonem.MP4"}
              type="video/mp4"
            ></video>
          </section>
        </div>
      </div>

      <div
        className="homepage-body"
      >

        <section className="slogan">
          <div className="slogan-title">
              <img
                className="quote-img"
                src="/assets/img/open-quote.png"
              />
              <h1 className="slogan-main">ALFAS représentant de la marque ISONEM en France.</h1>
          </div>
          <h2 className="slogan-submain">Fabricant de produits d’étanchéité et de peintures depuis 17 ans,
                                          implanté dans une centaine de pays, ALFAS & ISONEM vous
                                          proposent des solutions performantes adaptées à vos besoins.</h2>
          <ul>
            <li>Résine d’étanchéité circulable, résine d’étanchéité sous carrelage, résine
            d’étanchéité des fondations et murs enterrés…</li>
            <li>Peintures façade, peinture décorative, peinture d'isolation thermique, peinture
            anti fissure, peinture imperméable, peinture élastique, peinture pour piscine,
            peinture anti humidité.</li>
            <li>Résine de sol, résine époxy, résine polyuréthane, vernis transparent étanche aux
            liquides, résine à effet métallique.</li>
            <li>Mortier de réparation, mortier de cuvelage, mortier de ragréage, enduit hydrofuge.</li>
            <li>Protection incendie : extincteurs de nouvelle génération (spray et bouteille),
            peinture intumescente, peinture ignifuge…</li>
          </ul>
          <span className="slogan-end">En formulant les lois de la physique avec celles de la chimie ISONEM
          prend le rôle de leader innovateur dans le secteur.</span>
          <div className="ensavoirplusDiv"> 
            <Link to="/apropos"> 
              <button className="ensavoirplusBtn btn btn-border-4"> 
                EN SAVOIR PLUS 
              </button> 
            </Link> 
          </div> 
        </section>

        <img 
          className="transition-img" 
          src="/assets/img/separator.png" 
        /> 
 
        {
          !isMobile &&
          <section className="section-product-presentation"
            data-aos="fade-up" 
            data-aos-duration="4000">
            <div className="custom-title-div">
              <div className="small-h">NOS</div>
              <div className="big-h">Produits</div>
            </div>

            <div className="product-categories mrg-top-30">{marquesDom}</div>
          </section>
        }
        {
          isMobile &&
          <section className="section-product-presentation">
            <div className="custom-title-div">
              <div className="small-h">NOS</div>
              <div className="big-h">Produits</div>
            </div>

            <div className="product-categories mrg-top-30">{marquesDom}</div>
          </section>
        }

        <div className="big-triangle big-triangle-mrg-top">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="100%"
            height="100"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            style={{ fill: "#1c1c1c", padding: 0 }}
          >
            <path d="M0 100 L50 2 L100 100 Z"></path>
          </svg>
        </div>

        <section className="section-revendeurs" id="point-de-ventes">
          <div className="custom-title-div mrg-top-30">
            <div className="big-h big-h-gold">Points de vente</div>
          </div>

          <div className="revendeurs-div mrg-top-90">
            <div className="search-revendeur-div">
              <div className="search_bar">
                <TextField
                  id="name"
                  inputRef={textInputRevendeur} 
                  label="Code postal / Ville"
                  color="primary"
                  className="search_input"
                  onChange={e => filterRevendeurList(e.target.value)}
                  InputProps={{
                    startAdornment: (
                    <IconButton onClick={() => handleClear()} disabled={false} style={{order: 1}}>
                    <i className="fas fa-times"></i>
                    </IconButton>
                    )
                    }}
                    InputAdornmentProps={{
                    position: "end",
                    style: {order: 2, marginLeft: 0}
                    }}
                />
              </div>
              <div className="scrollable-revendeurs scrollbarCusto">
                {revendeursDom}
              </div>
            </div>

            <div id="container">{map}</div>
          </div>

          <div className="leroymerlin-alfas-div">
            <span>RETROUVEZ-NOUS&nbsp;&nbsp;SUR</span>
            <img onClick={() => {window.location.href = 'https://www.leroymerlin.fr/v3/search/search.do?keyword=isonem'} }
              src="/assets/img/alfas-leroy-merlin-isonem.png"/>
          </div>
        </section>

        { productOfMonth.nom && !isMobile &&
          <section className="section-product-of-month" data-aos="zoom-out">
            <div className="left-side">
              <img src={productOfMonth.image} alt={productOfMonth.nom}/>
            </div>
            <div className="right-side">
              <div className="custom-title-div align-left mrg-top-90 mrg-left-50">
                <div className="small-h">LE PRODUIT DU MOIS</div>
                <div className="big-h">{productOfMonth.nom}</div>
              </div>
              <div className="description-div mrg-left-50">
                <p
                  dangerouslySetInnerHTML={{
                    __html: productOfMonth.description
                  }}
                ></p>
              </div>

              <Link
                to={"/produit/" + productOfMonth.nom + "/" + productOfMonth.id}
              >
                <button className="outline-btn decouvrirBtn mrg-left-50">
                  Decouvrir
                </button>
              </Link>
            </div>
          </section>
        }

        { productOfMonth.nom && isMobile &&
          <section className="section-product-of-month">
            <div className="left-side">
              <img src={productOfMonth.image} alt={productOfMonth.nom}/>
            </div>
            <div className="right-side">
              <div className="custom-title-div align-left mrg-top-90 mrg-left-50">
                <div className="small-h">LE PRODUIT DU MOIS</div>
                <div className="big-h">{productOfMonth.nom}</div>
              </div>
              <div className="description-div mrg-left-50">
                <p
                  dangerouslySetInnerHTML={{
                    __html: productOfMonth.description
                  }}
                ></p>
              </div>

              <Link
                to={"/produit/" + productOfMonth.nom + "/" + productOfMonth.id}
              >
                <button className="outline-btn decouvrirBtn mrg-left-50">
                  Decouvrir
                </button>
              </Link>
            </div>
          </section>
        }

        <section
          className="section-contact mrg-top-90 padding-btm-footer"
          data-aos={isMobile? '' : 'fade-down'}
        >
          <div className="big-triangle-btm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="100%"
              height="100"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              style={{ fill: "#ffffff", padding: 0 }}
            >
              <path d="M-1 -1 L50 99 L101 -1 Z"></path>
            </svg>
          </div>

          <div className="custom-title-div mrg-top-90">
            <div id="contact" className="small-h">Nous sommes</div>
            <div className="big-h">à votre écoute</div>
          </div>

          <div className="contact-div mrg-top-30">
            <div className="contact-info">
              <div className="contact" data-aos={isMobile? '' : "fade-right"}>
                <i className="fas fa-map-marked"></i>
                <div className="text">
                  <a
                    href="https://goo.gl/maps/rNanSw4C6q1XTj8WA"
                    target="_blank"
                  >
                    117 Rue Du Puech, 30310 Vergèze
                  </a>
                </div>
              </div>

              <div
                className="contact"
                data-aos={isMobile? '' : "fade-right"}
                data-aos-delay="300"
              >
                <i className="fas fa-phone"></i>
                <div className="text">
                  <a href="tel:+33448710030">+33 4 48 71 00 30</a>
                </div>
              </div>

              <div
                className="contact"
                data-aos={isMobile? '' : "fade-right"}
                data-aos-delay="600"
              >
                <i className="fas fa-envelope"></i>
                <div className="text">
                  <a href="mailto:contact@alfas.fr">contact@alfas.fr</a>
                </div>
              </div>
            </div>

            <div
              className="contact-form mrg-top-30"
              data-aos={isMobile? '' : "fade-down"}
              data-aos-delay="600"
            >
              <ContactForm />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
