import React from "react";
import "./legal.scss";
import { Link  } from "react-router-dom";
import BurgerMenu from "../burgerMenu/burgerMenu";
import NormalMenu from "../normalMenu/normalMenu";

function Legal() {
  return (
    <div className="legal">
      <div
        className="legal-header"
      >
        <div className="top-header">
          <Link to="/">
            <img className="logo" src="/assets/img/alfas-isonem-france.png" />
          </Link>
          <NormalMenu/>
          <BurgerMenu isOpen={true} />
        </div>
      </div>
      <div className="legal-body">
        <h4 style={{ color: "#b79462" }}>MENTIONS LEGALES</h4>

        <p style={{ textAlign: "justify" }}>
          <strong> </strong>
          <br />
          Conformément aux dispositions des articles 6-III et 19 de la Loi n°
          2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique,
          dite L.C.E.N., nous portons à la connaissance des utilisateurs et
          visiteurs du site :{" "}
          <a
            style={{ color: "#b79462" }}
            href="https://alfas.fr"
            target="_blank"
          >
            www.alfas.fr
          </a>{" "}
          les informations suivantes :
        </p>

        <p style={{ textAlign: "justify", color: "#b79462" }}>
          <strong>ÉDITEUR</strong>
        </p>

        <p style={{ textAlign: "justify" }}>
          Le site{" "}
          <a
            href="https://alfas.fr"
            style={{
              color: "#b79462",
              fontFamily: "sans-serif, Arial, Verdana"
            }}
            target="_blank"
          >
            www.alfas.fr
          </a>
           est la propriété exclusive de <strong>la SARL </strong>
          <strong>Alfas</strong>, qui l'édite.
        </p>

        <p style={{ textAlign: "justify" }}>
          <strong>Alfas</strong>
          <br />
          <strong>SARL </strong> <br />
          <span>
            Tél  : <strong>+33 4 48 71 00 30</strong>{" "}
          </span>
        </p>

        <p style={{ textAlign: "justify" }}>
          <strong>117 RUE DU PUECH, </strong>
          <strong>30310 VERGEZE.</strong>
          <br />
          Immatriculée au Registre du Commerce et des Sociétés sous le numéro{" "}
          <strong>87928126900016.</strong>
          <strong> </strong>
        </p>

        <p style={{ textAlign: "justify" }}>
          Adresse de courrier électronique : <strong>contact@alfas.fr</strong> 
          <br />
           <br />
          Directeur de la  publication : <strong>Alfas</strong>
          <br />
          Contactez le responsable de la publication :{" "}
          <strong>contact@alfas.fr</strong>
        </p>

        <p style={{ textAlign: "justify", color: "#b79462" }}>
          <strong>HÉBERGEMENT</strong>
        </p>

        <p style={{ textAlign: "justify" }}>
          Le site est hébergé par 
          <strong>OVH : 2 rue Kellermann, 59100 Roubaix.</strong>
          <br />
        </p>

        <p style={{ textAlign: "justify", color: "#b79462" }}>
          <strong>DROITS D'AUTEUR</strong>
        </p>

        <p style={{ textAlign: "justify" }}>
          Ce site doit être considéré comme un tout indissociable. Les
          informations y figurant sont réservées à un usage exclusivement
          personnel et ne peuvent être en tout ou partie ni reproduites, ni
          communiquées. Certaines des données (textes, publications, sons ou
          images) figurant sur les pages de ce site ont fait l’objet d’une
          autorisation de publication, de diffusion ou d’un droit d’usage acquis
          auprès de tiers. Toute reproduction, représentation ou diffusion, à
          des fins autres que personnelles ou celles explicitement autorisées,
          en tout ou partie du contenu du site sur quelque support ou par tout
          procédé que ce soit, est interdite.
        </p>

        <p style={{ textAlign: "justify" }}>
          Le non-respect de cette interdiction constitue une contrefaçon
          susceptible d’engager la responsabilité civile et pénale du
          contrefacteur. Il est strictement interdit d’utiliser ou de reproduire
          les logos, seuls ou associés, à quelque titre que ce soit, et
          notamment à des fins publicitaires, sans l’accord préalable écrit des
          différentes structures.
        </p>
      </div>
    </div>
  );
}

export default Legal;
