import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Paper from "@material-ui/core/Paper";
import "./productGrid.scss";
import Background from "../../img/2.png";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  }
}));

export default function ProductGrid({ products, marque }) {
  const [spacing, setSpacing] = React.useState(3);
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={spacing}>
      {products.map(product => (
        <Grid key={product.id} item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Link to={"/produit/" + product.nom + "/" + product.id}>
            <div className="gridItem">
              <div className="img">
                <div
                  className="imgProduct"
                  style={{ backgroundImage: "url(" + product.image + ")" }}
                ></div>
              </div>
              <div className="productDescription">
                <div className="fournisseur">{marque}</div>
                <div className="productName">{product.nom}</div>
              </div>
            </div>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}
