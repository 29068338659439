/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./product.scss";
import BurgerMenu from "../burgerMenu/burgerMenu";
import { Link, useHistory } from "react-router-dom";
import api from "../../apis/api";
import NormalMenu from "../normalMenu/normalMenu";

function Product(props) {
  const [product, setProduct] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    if (props.match.params.id && props.match.params.id.length > 0) {
      setIsLoading(true);
      api.getProduct(props.match.params.id).then(res => {
        setProduct(res);
        setIsLoading(false);
      });
    }
    
  }, []);

  function openPdf() {
    var byteArray = new Uint8Array(product.ficheTechnique.data);
    var blob = new Blob([byteArray], { type: 'application/pdf' });
     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
       window.navigator.msSaveOrOpenBlob(blob);
     } else {
       var objectUrl = (window.URL ? URL : window.webkitURL).createObjectURL(blob);
       window.location.href = objectUrl;
     }
  }

  function openLienAchat() {
    window.location.href = product.liensAchat;
  }

  function goBack() {
    history.goBack()
  }

  function extractUrl(str) {
    return str? str.replace('https://youtu.be/', '') : '';
  }

  return (
    <div className="product">
      {
        isLoading && 
        <div className="loader-base">
          <div className="loader">
          </div>
        </div>
      }
      <div className={isLoading? 'product-header isLoadingDiv' : 'product-header' }>
        <div className="top-header">
          <Link to="/">
            <img
              className="logo"
              src="/assets/img/alfas-isonem-france.png"
            />
          </Link>
          <NormalMenu initialState="item2a"/>
          <BurgerMenu isOpen={true} />
        </div>
      </div>

      <div className={isLoading? 'product-body isLoadingDiv' : 'product-body' }>
        <section className="section-product">
          <div className="top">
            <div className="left">
              <div className="imgProduct"
                style={{ backgroundImage: "url(" + product.image + ")" }}>
              </div>
            </div>
            <div className="right">
              <div className="backTo">
                <div className="buttonBackTo" onClick={goBack}>
                  <i className="fas fa-long-arrow-alt-left"></i>
                  <span>Voir tous les produits</span>
                </div>
 

                {
                   product.ficheTechnique && product.ficheTechnique.data &&
                  <div className="ficheTechnique">
                    <img alt="pdf" src="/assets/img/pdfIcon.png"
                    onClick={openPdf}/>
                  </div>
                }

              </div>

              <div className="content">
                <div className="gammeTitle">
                  {product.gamme ? product.gamme.nom : ''}
                </div>
                <div className="title">
                  {product.nom}
                </div>

                <div className="description"
                dangerouslySetInnerHTML={{__html: product.description}}>
                </div>
              </div>

            </div>
          </div>
          <div className="bottom">

            <div className="bottom-flex">
              {
                product.proprietes && product.proprietes.length > 0 &&

                <div className="proprietes"
                  dangerouslySetInnerHTML={{__html: product.proprietes}}>
                </div>
              }

              {
                product.proprietes && product.proprietes.length > 0 &&
                product.liensAchat && product.liensAchat.length > 0 &&

                <button className="btn btn-border-4" onClick={openLienAchat}>ACHETER</button>
              }

            </div>


            {
              ((!product.proprietes || (product.proprietes && product.proprietes.length === 0)) &&
              (product.liensAchat && product.liensAchat.length > 0)) &&

                <div className="bottom-flex-end">
                  <button className="btn btn-border-4" onClick={openLienAchat}>ACHETER</button>
                </div>
            }

          {
            product.lienYoutube && product.lienYoutube.length > 0 &&
            <div>
            <iframe className="productVideo" width="560" height="315" 
              src={ 'https://www.youtube.com/embed/' + extractUrl(product.lienYoutube)}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
            </div>   
          }


   
            {
              product.specifications && product.specifications.length > 0 &&
              <div className="specifications-div">
                <img
                  className="quote-img"
                  src="/assets/img/open-quote.png"
                />
                <div className="specifications"
                  dangerouslySetInnerHTML={{__html: product.specifications}}>
                </div>
              </div>
            }
          </div>
        </section>
      </div>
    </div>
  );
}

export default Product;
