import React, { useState, useEffect } from "react";
import "./burgerMenu.scss";
import { Link } from "react-router-dom";
import Burger from "@animated-burgers/burger-slide";
// don't forget the styles
import "@animated-burgers/burger-slide/dist/styles.css";
import { HashLink } from 'react-router-hash-link';
import { useLocation, Switch } from "react-router-dom";

function BurgerMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [applyClosingEffect, setApplyClosingEffect] = useState(false);
  const location = useLocation();

  React.useEffect(() => {
    setIsOpen(false);
  }, [location]);

  document.addEventListener("click", evt => {
    let flyoutElement = document.getElementById("alfas-burger-menu");
    let targetElement = evt.target; // clicked element

    let previousElement;

    do {
      if (targetElement["id"] == "alfas-burger-menu" && !isOpen) {
        // openSideMenu();
        return;
      }
      // Go up the DOM
      previousElement = targetElement;
      targetElement = targetElement["parentNode"];
    } while (targetElement != undefined || targetElement != null);
    // This is a click outside.

    if (evt.target["id"] != "alfas-menu-content" &&
      evt.target["id"] != "burger-slide-alfas" &&
      previousElement["id"] != "alfas-burger-menu" && 
    previousElement["id"] != "alfas-burger-menu-sub"  && isOpen) {
      closeSideMenu();
    }
  });

  function closeSideMenu() {
    document.body.style.overflowY = "auto";
    document.body.style.pointerEvents = "auto";
    document.documentElement.style.overflowY = "auto";

    setApplyClosingEffect(true);

    setTimeout(() => {
      setApplyClosingEffect(false);
      setIsOpen(false);
    }, 750);
  }

  function openSideMenu() {
    document.body.style.overflowY = "hidden";
    document.documentElement.style.overflowY = "hidden";
    // document.body.style.pointerEvents = "none";

    setIsOpen(true);
  }

  function click() {
    if (!isOpen) {
      openSideMenu();
    } else {
      closeSideMenu();
    }
  }

  return (
    <div id="alfas-burger-menu" className="alfas-burger-menu">
      <div id="alfas-burger-menu-sub" onClick={click}>
        <Burger id="burger-slide-alfas" direction="left" isOpen={isOpen}>
          <div>dgdg</div>
        </Burger>
      </div>

      {isOpen && (
        <div
          className={
            isOpen && !applyClosingEffect
              ? "menu-alfas animate__animated animate__slideInRight animate__fast"
              : "menu-alfas animate__animated animate__slideOutRight animate__fast"
          }
        >
          <div id="alfas-menu-content" className="content">
            <Link to="/">
              <div id="item1" className="item">
                Alfas
              </div>
            </Link>

            <Link to="/apropos">
              <div id="item2" className="item">
                Qui Sommes Nous
              </div>
            </Link>

            <Link to="/produits">
              <div id="item3" className="item">
                Nos Produits
              </div>
            </Link>

            <HashLink to="/#point-de-ventes">
              <div id="item4"
              className="item">
                Points de Vente
              </div>
            </HashLink>

            <HashLink to="/#contact">
              <div id="item5"
              className="item">
                Contact
              </div>
            </HashLink>

            <HashLink to="/apropos#devenir-revendeur">
              <div id="item6"
              className="item">
                Devenir Revendeur
              </div>
            </HashLink>
          </div>
        </div>
      )}
    </div>
  );
}

export default BurgerMenu;
