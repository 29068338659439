import React, { useState, useEffect } from "react";
import "./normalMenu.scss";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

function NormalMenu(props) {

  const [selectedItem, setSelectedItem] = useState(props.initialState? props.initialState : '');

  return (
    <div id="alfas-normal-menu" className="alfas-normal-menu">
      <div id="alfas-normal-menu-content" className="content">
        <Link to="/apropos">
          <div id="aproposs"
          className={selectedItem === 'aproposs'? 'item selected-item' : 'item'}>
            Qui Sommes Nous
          </div>
        </Link>

        <Link to="/produits">
          <div id="item2a"
          className={selectedItem === 'item2a'? 'item selected-item' : 'item'}>
            Nos Produits
          </div>
        </Link>

        <HashLink to="/#point-de-ventes">
          <div id="item3a"
          className={selectedItem === 'item3a'? 'item selected-item' : 'item'}>
            Points de Vente
          </div>
        </HashLink>

        <HashLink to="/#contact">
          <div id="item3b"
          className={selectedItem === 'item3b'? 'item selected-item' : 'item'}>
            Contact
          </div>
        </HashLink>

        <HashLink to="/apropos#devenir-revendeur">
          <div id="item3c"
          className={selectedItem === 'item3c'? 'item selected-item' : 'item'}>
            Devenir Revendeur
          </div>
        </HashLink>
      </div>
    </div>
  );
}

export default NormalMenu;
