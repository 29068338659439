import React, {useState, useEffect} from "react";
import "./App.css";
import "./app.scss";
import Home from "./component/home/home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import About from "./component/about/about";
import Products from "./component/products/products";
import Product from "./component/product/product";
import Legal from "./component/legal/legal";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

function App() {
  const [displayGoTop, setDisplayGoTop] = useState(false);

  const theme = createMuiTheme({
    palette: {
      primary: {
        light: "#be9359",
        main: "#be9359",
        dark: "#8a6c43",
        contrastText: "#ffffff"
      },
      secondary: {
        light: "#b1b1b1",
        main: "#b1b1b1",
        dark: "#7d7d7d",
        contrastText: "#7d7d7d"
      }
    }
  });

  useEffect(
    () => {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > window.innerHeight) {
          if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 200) {
            setDisplayGoTop(false);
          } else {
            setDisplayGoTop(true);
          }
        } else {
          setDisplayGoTop(false);
        }
      })
    },
    []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/apropos">
              <About />
            </Route>
            <Route path="/produits/:marque/:gammeNom/:gammeId" render={(props) => <Products {...props} />}>
            </Route>
            <Route path="/produits/:marque" render={(props) => <Products {...props} />}>
            </Route>
            <Route path="/produits" render={(props) => <Products {...props} />}>
            </Route>
            <Route path="/produit/:nom/:id" render={(props) => <Product {...props} />}>
            </Route>
            <Route path="/legal" render={(props) => <Legal {...props} />}>
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
      {
        displayGoTop &&
        <button className="btn goTopBtn" onClick={(e) => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <i className="fas fa-chevron-up"></i>
        </button>
      }

    </div>
  );
}

export default App;
