/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./products.scss";
import ProductGrid from "../productGrid/productGrid";
import BurgerMenu from "../burgerMenu/burgerMenu";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Link, useHistory, useLocation  } from "react-router-dom";
import api from "../../apis/api";
import NormalMenu from "../normalMenu/normalMenu";

function Products(props) {
  // fournisseur meme chose que marque
  const [selectedFournisseur, setSelectedFournisseur] = useState("");
  const [marques, setMarques] = useState([]);
  const [marquesDom, setMarquesDom] = useState([]);

  const [selectedGamme, setSelectedGamme] = useState("");
  const [gammesOfSelectedMarque, setGammesOfSelectedMarque] = useState([]);
  const [gammesDom, setGammesDom] = useState([]);

  const [gridDom, setGridDom] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isDisplayingSideBar, setIsDisplayingSideBar] = useState(false);
  const [isHidingBody, setIsHiding] = useState(false);
  const [urlGammeId, setUrlGammeId] = useState(props.match.params.gammeId);

  let history = useHistory();

  let location = useLocation();

  useEffect(
    () => {
      if (location.pathname.includes('/produits/')) {
        const splitUrl = location.pathname.split('/')
        const gammeId = splitUrl[splitUrl.length - 1]
        if (location) {
          hideSideBar();
          setUrlGammeId(gammeId)
          updateProducts(gammeId);
          setSelectedGamme(gammeId);
        }
      }
    },
    [location]);


  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    if (props.match.params.marque && props.match.params.marque.length > 0) {
      setSelectedFournisseur(props.match.params.marque);
    }

    if (props.match.params.gammeId) {
      setUrlGammeId(props.match.params.gammeId)
      updateProducts(props.match.params.gammeId);
      setSelectedGamme(props.match.params.gammeId);
    }

    setIsLoading(true);

    api.getMarque().then(res => {
      setMarques(res);

      const marquesLocal = [];
      res.forEach((r, indx) => {
        marquesLocal.push(
          <img
            key={indx}
            alt={r.nom.toLowerCase()}
            className={
              selectedFournisseur === r.nom
                ? "fournisseur activeFournisseur"
                : "fournisseur"
            }
            src={r.logo}
            onClick={() => {
              hideSideBar();
            }}
          />
        );
      });
      setMarquesDom(marquesLocal);

      if ((!props.match.params.marque
        || ( props.match.params.marque
        && props.match.params.marque.length == 0 ))
        && res[0] && res[0].nom) {
        setSelectedFournisseur(res[0].nom); 
        updateProducts(props.match.params.gammeId);
      }

      if (!urlGammeId || urlGammeId === '') {
        setIsLoading(false);
      }

    });
    
  }, []);

  useEffect(() => {

    const marquesLocal = [];
    marques.forEach((m, indx) => {
        marquesLocal.push(
          <img
            key={indx}
            alt={m.nom}
            className={
              selectedFournisseur === m.nom
                ? "fournisseur activeFournisseur"
                : "fournisseur"
            }
            src={m.logo}
            onClick={() => {
              hideSideBar();
              setUrlGammeId('')
              setSelectedFournisseur(m.nom);
            }}
          />
        );

        if (selectedFournisseur === m.nom && m.gammes) {
          const gammeLocal = [];

          setGammesOfSelectedMarque(m.gammes);

          m.gammes.forEach((g, idxGamme) => {
            if (idxGamme === 0 && urlGammeId === selectedFournisseur) {
              gammeLocal.push(
                <div 
                  key={g.nom}
                  className="gamme activeGamme"
                >
                  {g.nom}
                </div>
              )
              history.replace('/produits/' + selectedFournisseur + '/' + g.nom + '/' + g.id, {
                gammeNom: g.nom,
                gammeId: g.id
              })
            } else {
              gammeLocal.push(
                <Link to={'/produits/' + selectedFournisseur + '/' + g.nom + '/' + g.id}>
                  <div 
                    key={g.id}
                    className={
                      selectedGamme === g.id ?
                      'gamme activeGamme'
                      : 'gamme'
                    }
                  >
                    {g.nom}
                  </div>
                </Link>
              )
            }
          })
          setGammesDom([ ...gammeLocal]);
        }
      });
      setMarquesDom(marquesLocal);
  }, [marques, selectedFournisseur]);

  useEffect(() => {
    if (marques) {
      marques.forEach((m, indx) => {
        if (selectedFournisseur === m.nom && m.gammes) {
          const gammeLocal = [];
          m.gammes.forEach((g) => {
            gammeLocal.push(
              <Link to={'/produits/' + selectedFournisseur + '/' + g.nom + '/' + g.id}>
                <div 
                  key={g.nom}
                  className={
                    selectedGamme === g.id ?
                    'gamme activeGamme'
                    : 'gamme'
                  }
                >
                  {g.nom}
                </div>
              </Link>
            )
          })
          setGammesDom(gammeLocal);
        }
      });
    }
  }, [selectedGamme]);

  useEffect(() => {
    marques.forEach((m, indx) => {
      if (selectedFournisseur === m.nom && m.gammes) {
        const gammeLocal = [];
        m.gammes.forEach((g, idxGamme) => {
          if (idxGamme === 0 && (!urlGammeId || urlGammeId === '')) {
            updateProducts(g.id);
            setSelectedGamme(g.id);
            gammeLocal.push(
              <div 
                key={g.nom}
                className="gamme activeGamme"
              >
                {g.nom}
              </div>
            )
            history.replace('/produits/' + selectedFournisseur + '/' + g.nom + '/' + g.id, {
              gammeNom: g.nom,
              gammeId: g.id
            })
          } else {
            if (idxGamme === 0) {
              updateProducts(g.id);
            }
            gammeLocal.push(
              <Link to={'/produits/' + selectedFournisseur + '/' + g.nom + '/' + g.id}>
                <div 
                  key={g.nom}
                  className={
                    selectedGamme === g.id ?
                    'gamme activeGamme'
                    : 'gamme'
                  }
                >
                  {g.nom}
                </div>
              </Link>
            )
          }
        })
        setGammesDom([ ...gammeLocal]);
      }
    });

  }, [marquesDom]);

  function updateProducts(gammeId) {
    let grid;
    if (gammeId && selectedFournisseur) {
      setIsLoading(true);
      api.getGamme(gammeId).then((res) => {
        grid = <ProductGrid products={res.produits} marque={selectedFournisseur}/>;
        setGridDom({ ...grid});
        setIsLoading(false);
      })
    } else {
      grid = <div></div>
      setGridDom(grid);
    }
  }

  function displaySideBar() {
    setIsDisplayingSideBar(true);
    setTimeout(() => {
      setIsHiding(true);
    }, 450);
  }

  function hideSideBar() {
    setIsDisplayingSideBar(false);
    setIsHiding(false);
  }

  let currentGammeName = '';
  if (gammesOfSelectedMarque.find((g) => g.id === selectedGamme)) {
    currentGammeName = gammesOfSelectedMarque.find((g) => g.id === selectedGamme).nom
  }

  return (
    <div className={isDisplayingSideBar ? 'products productDisplaySideBar' : 'products'}>
      {
        isLoading && 
        <div className="loader-base">
          <div className="loader">
          </div>
        </div>
      }

      <div className={isLoading? 'products-header isLoadingDiv' : 'products-header'}>
        <div className="top-header">
          <Link to="/">
            <img
              className="logo"
              src="/assets/img/alfas-isonem-france.png"
            />
          </Link>
          <NormalMenu initialState="item2a"/>
          {
            !isDisplayingSideBar &&
            <BurgerMenu isOpen={true} />
          }
        </div>
      </div>

      <div className={isLoading? 'products-body isLoadingDiv' : 'products-body'}>
        
        <div className={isDisplayingSideBar ? 'left-side-bar sideBarMobileDisplay animate__animated animate__slideInLeft animate__faster' : 'left-side-bar'}>
          {
            isDisplayingSideBar &&
            <div className="closeDiv" onClick={hideSideBar}>
              <i className="fas fa-times"></i>
            </div>
          }
          <div className="fournisseur-title">Marques</div>

          <div className="fournisseurs">
            {marquesDom}
          </div>

          <div className="gamme-title">Gammes</div>

          <div className="gammes">
            {gammesDom}
          </div>
        </div>

        <div className="side-bar-btn" onClick={displaySideBar}>
          <i className="fas fa-chevron-right"></i>
        </div>

        <section className={isHidingBody ? "section-products hideMobileProducts" : "section-products"}>
          <div>
            <div className="gamme-name">{currentGammeName}</div>
            {gridDom}
          </div>
        </section>
      </div>
    </div>
  );
}

export default Products;
